const selector = 'data-js-form-dirty-tracker'
const states = [];

const isDirtyState = (state) => (
  Object.keys(state.values).reduce((accumulator, key) => (
    accumulator || state.values[key].original !== state.values[key].current
  ), false)
)
const trackFormChangesFor = (type, index) => {
  const state = states[index];
  const { form } = state;

  $(form).find(type).each((i, input) => {
    const name = $(input).attr('name')

    if (name) {
      const value = $(input).val()
      // store original value
      state.values[name] = { original: value, current: value }

      // add event listener tracking changes
      $(input).on('change', (e) => {
        state.values[name].current = $(input).val()
        state.isDirty = isDirtyState(state)
      })
    }
  })
}

const initializeFormTracker = (index, form) => {
  states[index] = { index, form, isDirty: false, values: {} }

  trackFormChangesFor('input', index)
  trackFormChangesFor('select', index)
  trackFormChangesFor('textarea', index)
}

let isFormSubmitting = false
const areStatesDirties = () => {
  return states.reduce((accumulator, state) => (accumulator || state.isDirty), false)
}
const displayUnloadMessage = () => {
  return areStatesDirties() && !isFormSubmitting
}

export const initialize = () => {
  const $forms = $(`[${selector}]`)
  if (!$forms.length) { return }

  $forms.each((index, form) => {
    initializeFormTracker(index, form);
    $(form).on('submit', () => { isFormSubmitting = true })
  })

  window.addEventListener('beforeunload', (event) => {
    if (!displayUnloadMessage()) { return; }

    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  });
};

export default initialize;
