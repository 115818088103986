const maxDate = "2099-12-31";
const maxTime = "23:59";

document.addEventListener("DOMContentLoaded", function () {
  manageTipologySelection();
});

var renewButtonsCollapse = document.querySelectorAll('[id^="renew-"]');

renewButtonsCollapse.forEach(function (btn) {
  btn.addEventListener("click", () => {
    manageTipologySelection();
  });
});

const manageTipologySelection = () => {
  const TypologyInputs = $('[name$="[activation_typology]"]');
  const durationInputs = $('[name$="[duration]"]');
  const validUntilDateInputs = $('[name$="[valid_until_date]"]');
  const validUntilTimeInputs = $('[name$="[valid_until_time]"]');

  durationInputs.each(function (durationInputsIndex) {
    const fieldValue = this.dataset.fieldValue;
    const duration = parseInt(this.dataset.duration);
    const licenceWithExpiration = this.dataset.withExpiration;
    const licenceTypology = TypologyInputs[durationInputsIndex];

    if (licenceTypology.value == licenceTypology.dataset.selected) {
      // promo
      console.log("config. promo at load");
      if (this.dataset.wl == "bsmart") {
        $(function () {
          document.addEventListener(
            "invalid",
            (e) => {
              e.preventDefault();
            },
            true
          );
        });
      }
      if (licenceWithExpiration == "true" && duration < 180) {
        console.log("licence withExpiration & duration < 180");
        $(this).val(duration);
      } else {
        console.log("licence not withExpiration or with duration > 180");
        $(this).val(fieldValue);
      }
    } else {
      // cattedra
      console.log("config. cattedra at load");
      $(function () {
        document.addEventListener(
          "invalid",
          (e) => {
            e.preventDefault();
          },
          true
        );
      });
      $(this).prop("readonly", true);
      if (licenceWithExpiration == "true") {
        $(this).val(duration);
      } else {
        validUntilDateInputs.each(function (dateInputIndex) {
          if (durationInputsIndex == dateInputIndex) {
            $(this).val(maxDate);
            $(this).trigger("change");
          }
        });
      }
    }
    $(this).trigger("change");
  });

  validUntilTimeInputs.each(function () {
    $(this).val(maxTime);
    $(this).trigger("change");
  });
};

// promo
const promoTipologySelected = (event) => {
  const durationInput = $(event.target).closest("tr").find('[name$="[duration]"]');
  const validUntilDateInput = $(event.target).closest("tr").find('[name$="[valid_until_date]"]');
  const validUntilTimeInput = $(event.target).closest("tr").find('[name$="[valid_until_time]"]');
  console.log("selected promo");

  let licenceDataset = durationInput[0].dataset;
  const licence_duration = licenceDataset.wl === "samir" ? 90 : 180;

  durationInput.prop("readonly", false);
  validUntilDateInput.prop("readonly", true);
  validUntilTimeInput.prop("readonly", true);

  //if (licenceDataset.wl == "elionline") {
  //  validUntilDateInput.val(moment().startOf("day").add(180, "days").format("YYYY-MM-DD"));
  //if (licenceDataset.wl == "bsmart") {
  //   if (licenceDataset.duration >= 180) {
  //     validUntilDateInput.val(moment().startOf("day").add(180, "days").format("YYYY-MM-DD"));
  //   } else {
  if (licenceDataset.withExpiration == "true" && licenceDataset.duration < 180) {
    console.log("licence withExpiration & duration < 180");
    durationInput[0].setAttribute("max", parseInt(licenceDataset.duration));
    validUntilDateInput.val(moment().endOf("day").add(parseInt(licenceDataset.duration), "days").format("YYYY-MM-DD"));
  } else {
    console.log("licence not withExpiration or with duration > 180");
    validUntilDateInput.val(moment().startOf("day").add(licence_duration, "days").format("YYYY-MM-DD"));
  }
  // }
  //   } else {
  //     if (licenceDataset.withExpiration == "true") {
  //         durationInput[0].setAttribute("max", parseInt(licenceDataset.duration));
  //         validUntilDateInput.val(moment().endOf("day").add(parseInt(licenceDataset.duration), "days").format("YYYY-MM-DD"));
  //     } else {
  //         validUntilDateInput.val(moment().startOf("day").add(180, "days").format("YYYY-MM-DD"));
  //     }
  //   }

  validUntilTimeInput.val(maxTime);
  validUntilDateInput.trigger("change");
  validUntilTimeInput.trigger("change");
};

// cattedra
const cattedraTipologySelected = (event) => {
  let durationInput = $(event.target).closest("tr").find('[name$="[duration]"]');
  let validUntilDateInput = $(event.target).closest("tr").find('[name$="[valid_until_date]"]');
  let validUntilTimeInput = $(event.target).closest("tr").find('[name$="[valid_until_time]"]');
  let licenceDataset = durationInput[0].dataset;
  console.log("selected cattedra");

  durationInput.prop("readonly", true);
  validUntilDateInput.prop("readonly", true);
  validUntilTimeInput.prop("readonly", true);

  if (licenceDataset.withExpiration == "true") {
    console.log("switch promo -> cattedra a not withExpiration licence");
    validUntilDateInput.val(moment().endOf("day").add(parseInt(licenceDataset.duration), "days").format("YYYY-MM-DD"));
  } else {
    console.log("switch promo -> cattedra a withExpiration licence");
    validUntilDateInput.val(maxDate);
  }

  validUntilTimeInput.val(maxTime);
  validUntilDateInput.trigger("change");
  validUntilTimeInput.trigger("change");
};

// Update valid_until inputs when duration inputs change
$(document).on("change keyup", '[name$="[duration]"]', (event) => {
  console.log("change keyup duration field");
  const durationInput = $(event.target).closest("tr").find('[name$="[duration]"]');
  const validUntilDateInput = $(event.target).closest("tr").find('[name$="[valid_until_date]"]');
  const validUntilTimeInput = $(event.target).closest("tr").find('[name$="[valid_until_time]"]');

  let DateInputStr = validUntilDateInput[0];
  let createdAtDate = new Date(Date.parse(DateInputStr.dataset.createdAt));
  let durationDataset = durationInput[0].dataset;
  // only Wl hachette: in licence not expired and not unlimited, to renew activation is used created_at
  if (durationDataset.useCreatedAt == "true") {
    console.log("durationDataset.useCreatedAt == true");
    createdAtDate.setDate(createdAtDate.getDate() + parseInt(durationInput[0].value));
    DateInputStr.value = createdAtDate.toISOString().substr(0, 10);
  } else {
    console.log("durationDataset.useCreatedAt == false");
    validUntilDateInput.val(moment().endOf("day").add(parseInt(durationInput.val()), "days").format("YYYY-MM-DD"));
  }
  validUntilTimeInput.val(moment().endOf("day").add(parseInt(durationInput.val()), "days").format("HH:mm"));
});

// Update duration inputs when valid_until inputs change
$(document).on("change", '[name$="[valid_until_date]"]', (event) => {
  console.log("update duration with 2099/12/31 date");
  const durationInput = $(event.target).closest("tr").find('[name$="[duration]"]');
  const validUntilDateInput = $(event.target).closest("tr").find('[name$="[valid_until_date]"]');

  durationInput.val(moment(validUntilDateInput.val()).diff(moment().endOf("day"), "days") + 1);
});

// Show or hide the duration fields based on the selected activation typology
$(document).on("change", '[name$="[activation_typology]"]', (event) => {
  const selectedValue = $(event.target).children(":selected").val();

  if (selectedValue == $(event.target).data("selected")) {
    // promo
    promoTipologySelected(event);
  } else {
    // cattedra
    cattedraTipologySelected(event);
  }
});

// Disable the duration fields by default since
// the default activation typology is saggio cattedra
manageTipologySelection();
