// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();

var jQuery = ($ = require('jquery')); // bootstrap-datepicker need this global var
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import 'bootstrap';
import 'bootstrap-datepicker';
import "bootstrap-select";

import moment from "moment";
window.moment = moment;

import behaviours from "../behaviours/index";

require("perfect-scrollbar/css/perfect-scrollbar.css");
window.PerfectScrollbar = require("perfect-scrollbar/dist/perfect-scrollbar");

import "../script/scripts.bundle.js";
import "../script/renew.js";
import DualListbox from "dual-listbox";

$(document).ready(function () {
 // TODO: move bindings to another file ASAP

 $($(".flash-message").get().reverse()).each(function (index, item) {
  setTimeout(function () {
   $(item).find(".close").trigger("click");
  }, 4000 * (index + 1));
 });

 $(".dual-listbox").each(function () {
  const data = $(this).data();
  console.log(data);
  new DualListbox($(this).get(0), {
   availableTitle: data.availableTitle,
   selectedTitle: data.selectedTitle,
   searchPlaceholder: data.searchText,
   addButtonText: ">",
   removeButtonText: "<",
   addAllButtonText: ">>",
   removeAllButtonText: "<<",
  });
 });

 $(".inline-datepicker").datepicker({});
 $("select#q_brands_name_in").selectpicker();
 $("select#brands").selectpicker();

 // Initialize behaviours
 Object.keys(behaviours).forEach((key) => {
  behaviours[key].initialize();
 });

 var dropDown = document.getElementById('publisher_info_key_select');
 var itemSelectedChilds = dropDown.childNodes;
 dropDown.addEventListener('change', () => {
   // disable submit if nothing is selected, enable otherwise
   if (dropDown.value === '') {
     document.getElementById("search-key-submit").setAttribute('disabled', 'disabled');
   } else {
     document.getElementById("search-key-submit").removeAttribute('disabled');
   }
   itemSelectedChilds.forEach((cn) => {
     if (cn.value == dropDown.value) {
       var siae = cn.getAttribute('data-siae');
       var modalForm = dropDown.closest('form');
       var code_fields = modalForm.querySelectorAll('[type="search"]');
       if (siae == 'true') {
         code_fields[1].removeAttribute('disabled');
       } else {
         code_fields[1].value = '';
         code_fields[1].setAttribute('disabled', 'disabled');
       }
     }
   });
 });

 dropDown.dispatchEvent(new Event('change'));
});

// require('../src/plugins/flaticon/flaticon.css')
// require('../src/plugins/flaticon2/flaticon.css')
// require('../src/plugins/keenthemes-icons/font/ki.css')

// window.Popper = require('popper.js')

// require('bootstrap-select/dist/css/bootstrap-select.css')
// require('bootstrap-select')

// // require('tempusdominus-bootstrap-4')
